import React, { FC, useContext } from "react";
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// Contexts
import GlobalContext from "./../contexts/globalContext";

// Types
interface IProps {
  className?: string;
  onAnchorLinkClick: () => void;
  stripHash?: boolean;
  to: string;
}

// Assets
import Logo from "./../images/svg/logo-black.svg";

// Styles
const StyledBrand = styled((props) => <AnchorLink {...props} />)`
  height: 82px;
  text-decoration: none;

  .logo-icon {
    height: 60px;
    margin: 0;
    width: 60px;
  }

  .company-name {
    color: var(--black);
    font-size: 1.175rem;
    font-weight: 700;
    margin-left: 0.375rem;
    margin-right: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.375rem;
      padding-bottom: 0.25rem;
    }
  }
`;

const Brand: FC<IProps> = () => {
  const context = useContext(GlobalContext);

  const { setActive } = context;

  return (
    <StyledBrand
      className="d-flex align-items-center"
      to={`/#topPage`}
      onAnchorLinkClick={() => {
        setActive !== undefined && setActive(-1);
      }}
    >
      <Logo className="logo-icon" />
      <span className="company-name">Vista Lago</span>
    </StyledBrand>
  );
};

export default Brand;

import React, { useState, useContext, FC } from "react";
import classNames from "classnames";
import styled from "styled-components";

// Contexts
import GlobalContext from "./../contexts/globalContext";

// Assets
import ChatIcon from "./../images/svg/chat.svg";
import WhatsappIcon from "./../images/svg/whatsapp.svg";
import MessengerIcon from "./../images/svg/messenger.svg";

// Styles
const StyledChat = styled.div`
  align-items: center;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1rem;
  z-index: 200;

  @media (min-width: 768px) {
    bottom: 2rem;
    right: 2rem;
  }

  .chat {
    background-color: var(--white);
    border: 2px solid #000;
    border-radius: 50%;
    cursor: pointer;
    height: 62px;
    padding: 10px;
    transition: all 0.25s;
    width: 62px;
    z-index: 99999;

    &.open {
      background-color: var(--gin);
    }
  }

  .whatsapp,
  .messenger {
    bottom: 12px;
    height: 40px;
    position: absolute;
    transition: all 0.25s ease-out;
    width: 40px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .whatsapp {
    &.open {
      bottom: 76px;
      height: 46px;
      width: 46px;

      svg {
        height: 46px;
        width: 46px;
      }
    }
  }

  .messenger {
    &.open {
      bottom: 138px;
      height: 44px;
      width: 44px;

      svg {
        height: 44px;
        width: 44px;
      }
    }
  }
`;

const Chat: FC = () => {
  const [open, setOpen] = useState(false);

  const context = useContext(GlobalContext);

  const { whatsapp, messenger } = context;

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <StyledChat>
      <a
        href={messenger}
        target="_blank"
        rel="noreferrer"
        className={classNames("messenger", { open })}
      >
        <MessengerIcon />
      </a>
      <a
        href={whatsapp}
        target="_blank"
        rel="noreferrer"
        className={classNames("whatsapp", { open })}
      >
        <WhatsappIcon />
      </a>
      <div className={classNames("chat", { open })} onClick={handleOpen}>
        <ChatIcon />
      </div>
    </StyledChat>
  );
};

export default Chat;

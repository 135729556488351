import React, { ReactNode, FC } from "react";
import { I18nextProvider } from "react-i18next";

// Components
import MenuClose from "./menuClose";
import Header from "./header";
import MenuVertical from "./menuVertical";
import Footer from "./footer";
// import Cookie from "./cookie";
import Chat from "./chat";

// Translate
import i18next from "./../i18e";

React.useLayoutEffect = React.useEffect;

interface IProps {
  children: ReactNode | ReactNode[];
}

const Layout: FC<IProps> = ({ children }) => (
  <I18nextProvider i18n={i18next}>
    <div id="topPage"></div>
    <MenuClose />
    <Header />
    <main className="main" id="main">
      <MenuVertical />
      {children}
    </main>
    <Footer />
    {/* TODO: Fix cookie */}
    {/* <Cookie /> */}
    <Chat />
  </I18nextProvider>
);
export default Layout;

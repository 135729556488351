import React, { FC, useContext, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import NavigationAnchorBottom from "./navigationAnchorBottom";

// Contexts
import GlobalContext from "./../contexts/globalContext";

// Asets
import HomeIcon from "./../images/svg/home.svg";
import EnvelopeIcon from "./../images/svg/envelope.svg";
import PhoneIcon from "./../images/svg/phone.svg";

// Types
interface IMenuItemProps {
  anchor: string;
  name: string;
  translate: string;
}

// Styles
const StyledFooter = styled.div`
  padding: 2.5rem 0;

  @media (min-width: 768px) {
    padding: 4.5rem 0;
  }

  svg {
    fill: var(--white);
    height: 30px;
    width: 30px;

    @media (min-width: 768px) {
      height: 32px;
      width: 32px;
    }
  }
`;

const Footer: FC = () => {
  const { t } = useTranslation();

  const context = useContext(GlobalContext);

  const { menu, setActive } = context;

  const renderNavigation = useMemo(() => {
    return menu.map((menuItem: IMenuItemProps, menuIndex: number) => {
      const isLast = menu.length !== menuIndex + 1;

      return (
        <NavigationAnchorBottom
          item={menuItem}
          to={`/${menuItem.anchor}`}
          index={menuIndex}
          stripHash
          onAnchorLinkClick={() => {
            setActive !== undefined && setActive(menuIndex);
          }}
          isLast={isLast}
          key={`nav-${menuIndex}`}
        >
          {t(menuItem.translate)}
        </NavigationAnchorBottom>
      );
    });
  }, [menu]);

  return (
    <StyledFooter className="text-center text-lg-start bg-gun-powder text-muted">
      <section>
        <Container>
          <Row>
            <Col xs={10} sm={12} md={6} className="mx-auto text-start">
              <h2 className="text-white mb-4 mb-lg-5">{t(`footer.links`)}</h2>
              {renderNavigation}
            </Col>
            <Col
              xs={10}
              sm={12}
              md={6}
              className="mx-auto mt-5 mt-md-0 text-start"
            >
              <h2 className="text-white mb-4 mb-lg-5">{t(`footer.heading`)}</h2>
              <p className="text-white mb-3 d-flex align-items-center">
                <HomeIcon className="me-3" /> {t(`footer.address`)}
              </p>
              <p className="text-white mb-3 d-flex align-items-center">
                <EnvelopeIcon className="me-3" />
                vistalagoarenal@gmail.com
              </p>
              <p className="text-white mb-0 d-flex align-items-center">
                <PhoneIcon className="me-3" /> {t(`footer.phone`)}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </StyledFooter>
  );
};

export default Footer;

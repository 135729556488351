import React, { FC, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ClassNames from "classnames";

// Contexts
import GlobalContext from "./../contexts/globalContext";

// Types
import { INavigationAnchorProps } from "./../modules/interfaces";

// Styles
const StyledAnchorLink = styled((props) => <AnchorLink {...props} />)`
  border-bottom: 2px solid transparent;
  color: var(--white);
  display: block;
  margin: 0 1.25rem 0 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  transition: all 0.125s ease-out;
  z-index: 1;

  &:hover {
    border-bottom: 2px solid var--(terracotta);
    color: var(--black);
  }

  &:visited {
    color: var(--black);
  }

  &:focus {
    outline: none;
  }
`;

const NavigationAnchorBottom: FC<INavigationAnchorProps> = ({
  item,
  index,
  isLast,
}) => {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const { anchor, translate } = item || null;
  const { setActive } = context;

  return (
    <StyledAnchorLink
      to={`/${anchor}`}
      stripHash
      onAnchorLinkClick={() => {
        setActive !== undefined && setActive(index);
      }}
      className={ClassNames(
        "text-white",
        { "mb-0": !isLast },
        { "mb-1 mb-lg-3": isLast }
      )}
    >
      {t(translate)}
    </StyledAnchorLink>
  );
};

export default NavigationAnchorBottom;

import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ClassNames from "classnames";

// Contexts
import GlobalContext from "./../contexts/globalContext";

// Types
import { INavigationAnchorProps } from "./../modules/interfaces";

const NavigationAnchorTop: FC<INavigationAnchorProps> = ({ item, index }) => {
  const { t } = useTranslation();
  const context = useContext(GlobalContext);
  const { anchor, translate } = item || null;
  const { active, setActive } = context;

  return (
    <AnchorLink
      to={`/${anchor}`}
      stripHash
      onAnchorLinkClick={() => {
        setActive !== undefined && setActive(index);
      }}
      className={ClassNames("nav-link default-navigation-link", {
        active: index === active,
      })}
    >
      {t(translate)}
    </AnchorLink>
  );
};

export default NavigationAnchorTop;

import { createContext } from "react";

// Types
interface IMenuItemProps {
  anchor: string;
  name: string;
  translate: string;
}

// Types
interface IGlobalContext {
  menu: IMenuItemProps[];
  active?: number;
  setActive?: (active: number) => void | undefined;
  facebook?: string;
  instagram?: string;
  language?: number;
  setLanguage?: (active: number) => void | undefined;
  open?: boolean | undefined;
  toggleMenu?: (open: boolean) => void | undefined;
  phone?: string;
  phoneRef?: string;
  whatsapp?: string;
  messenger?: string;
}

// Defaults
const defaultState = {
  menu: [
    {
      anchor: "",
      name: "",
      translate: "",
    },
  ],
  active: 0,
  setActive: undefined,
  facebook: "",
  instagram: "",
  language: 0,
  setLanguage: undefined,
  open: false,
  toggleMenu: undefined,
  phone: "",
  phoneRef: "",
  whatsapp: "https://wa.me/50683274040",
  messenger: "http://m.me/vistalagoarenal",
};

const GlobalContext = createContext<IGlobalContext>(defaultState);

export default GlobalContext;
